import React, { useState } from "react";
import { db } from "../firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import Carousel from "../features/Carousel";
import Books from "../features/Books";
import storiy from "../images/storiy-logo.svg";
import magicButton from "../images/magic-button.svg";
import profileIcon from "../images/profile-icon.svg";
import Profilescreen from "../screens/Profilescreen";
import "../style/App.css";

function Homescreen() {
  const [drop, setDrop] = useState(false);

  const getData = async () => {
    try {
      const dateNow = new Date();
      const dateNowInMillis = dateNow.valueOf();

      const docSnap = await getDoc(doc(db, "readers", window.$userId));
      const milliSecondsAfterOneMonth = docSnap
        .data()
        .endVerificationDate.toMillis();

      if (dateNowInMillis > milliSecondsAfterOneMonth) {
        const changeUser = {
          userVerified: false,
        };

        await updateDoc(doc(db, "readers", window.$userId), changeUser);
      }
    } catch (error) {
      //error occured
    }
  };

  getData();

  return (
    <div className="home-screen-body"
      onClick={() => {
        if (drop) {
          setDrop(false);
        }
      }}
    >
      <header>
        <div
          className="home-screen-header"
          onClick={() => {
            if (drop) {
              setDrop(false);
            }
          }}
        >
          <a href="https://muro98.com" target="_blank" rel="noopener noreferrer">
            <img className="magic-button" src={magicButton} alt="magic-button" />
          </a>
          <a href="https://storiy.co.tz">
            <img className="home-screen-logo" src={storiy} alt="storiy" />
          </a>
          
          <div
            className="profile-button"
            onClick={() => {
              setDrop(true);
            }}
          >
            <img className="home-screen-profile" src={profileIcon} alt="profile" />
          </div>

          <div className={`profile-button-${drop ? "active" : "inactive"}`}>
            <Profilescreen />
          </div>
        </div>

      </header>
      <Carousel />
      <Books />
    </div>
  );
}

export default Homescreen;
