import React, { useRef, useState } from "react";
import { auth } from "../firebase";
import Signupscreen from "./Signupscreen";
import storiy from "../images/storiy-logo.svg";
import muro98 from "../images/muro98.svg";
import "../style/Loginscreen.css";

function LogInscreen() {
  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const [signUp, setSignUp] = useState(false);
  const [errorSignIn, setErrorSignIn] = useState(" ");

  const signIn = (e) => {
    e.preventDefault();
    auth
      .signInWithEmailAndPassword(
        emailRef.current.value,
        passwordRef.current.value
      )
      .then((authUser) => {
        //console.log(authUser);
      })
      .catch(() => {
        setErrorSignIn(
          "Akaunti yako haijasajiliwa!Bonyeza chini ya Log in kujisajili sasa."
        );

        setTimeout(function() {
          setErrorSignIn(" ");
        }, 5000);
      });
  };

  return (
    <div>
      <div className="login-screen-content">
        {signUp ? (
          <Signupscreen />
        ) : (
          <div>

            <form className="login-form">
            <img className="login-screen-logo" src={storiy} alt="storiy_logo" />
              <p className="error-message">{errorSignIn}</p>
              <input
                type="email"
                ref={emailRef}
                placeholder="Email..."
              />
              <input type="password" ref={passwordRef} placeholder="Password..." />
              <button type="submit" onClick={signIn}>
                <b>Log In</b>
              </button>
              <p className="jisajili-size">
                Bado hujajisajili na Storiy?Kujisajili {" "}
                <span
                  className="signup-link"
                  onClick={() => {
                    setSignUp(true);
                  }}
                >
                  Bonyeza hapa
                </span>
              </p>
              <img className="login-screen-muro" src={muro98} alt="muro98 logo" />
            </form>
           
          </div>
        )}
      </div>
    </div>
  );
}

export default LogInscreen;
