import React from "react";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "../axios";
import storiy from "../images/storiy-logo.svg";
import "../style/Descriptionscreen.css";
import placeholder from "../images/placeholder.jpg";
import bookPlaceHolder from "../images/book-placeholder.jpg";
import Spinner from "../features/Spinner";
import Status from "../features/Status";

import { db } from "../firebase";
import { doc, getDoc } from "firebase/firestore";

function Descriptionscreen() {
  const [userVerified, setUserVerified] = useState(false);
  const [book, setBook] = useState([]);
  const [author, setAuthor] = useState("");
  const [price, setPrice] = useState("");
 // const [link, setLink] = useState(""); 
  const [freeChapters, setFreeChapters] = useState(0);
  const [cover, setCover] = useState([]);
  const [isHover, setIsHover] = useState(false);
  const [imgSource, setimgSource] = useState([]);
  const [description, setDescription] = useState([]);
  const [statusDescription, setStatusDescription] = useState("");
  const [premiumBook, setPremiumBook] = useState([]);
  const [index, setIndex] = useState([]);
  const [status, setStatus] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const { num } = useParams();



  useEffect(() => {
    async function fetchBooks() {
      const response = await axios.get("/books.php");
      const data = await response.data;

      let bookTitle = data[num - 1].title;
      let bookCover = data[num - 1].coverUrl;
      let bookImgSource = data[num - 1].source;
      let bookDescription = data[num - 1].description;
      let bookIndex = data[num - 1].index;
      let bookStatus = data[num - 1].status;
      let bookAuthor = data[num - 1].author;
      let bookPrice = data[num - 1].price;
      let bookFreeChapters = data[num - 1].freeChapters;

      setBook(bookTitle);
      setCover(bookCover);
      setimgSource(bookImgSource);
      setDescription(bookDescription);
      setIndex(bookIndex);
      setStatus(bookStatus);
      setAuthor(bookAuthor);
      setPrice(bookPrice);
      setFreeChapters(bookFreeChapters);

      return response;
    }

    const getData = async () => {
      try {
        const docSnap = await getDoc(doc(db, "readers", window.$userId));
        setUserVerified(docSnap.data().userVerified);
        setPremiumBook(docSnap.data().premiumBookCollection);
      } catch (error) {
        //error occured
      }
    };

    fetchBooks();
    getData();

    if (book.length > 1) {
      console.log("dont spin b");
      setSpinner(false);
    } else {
      console.log("spin b");
      setSpinner(true);
    }

    var dottedString = premiumBook.join(":");

    if (status === 2 && userVerified === false) {
      setStatusDescription(
        `Ili uweze kusoma kitabu hiki cha ${book} pamoja na vitabu vingine vilivyopo kwenye kifurushi cha subscription utahitajika kulipia Tshs 2,500 kwa mwezi. Kiasi hichi utalipia kupitia M-PESA namba 0767 456 850 au HALOPESA namba 0621 842 779 au AIRTEL MONEY namba 0783 377 152 jina YUSUPH DAUDI MURO. Kisha utaandika neno Storiy kwenda Whatsapp namba 0767 456 850 au ujumbe wa kawaida (SMS). Kwa sasa utaweza kusoma Chapter ${freeChapters} za mwanzo`
      );
     // setLink("/malipo");
    }

    if (status === 3 && dottedString.indexOf(book) === -1) {
      setStatusDescription(
        `Kitabu cha ${book} kipo katika orodha ya vitabu vya Premium. Ili kuweza kusoma kitabu hiki utatakiwa kulipia kiasi cha ${price} kupitia M-PESA namba 0767 456 850 au HALOPESA namba 0621 842 779 au AIRTEL MONEY namba 0783 377 152 jina YUSUPH DAUDI MURO.      Kisha utaandika neno Storiy pamoja na kitabu ulichokinunua kwenda Whatsapp namba 0767 456 850 au ujumbe wa kawaida (SMS). Kwa sasa utaweza kusoma Chapters ${freeChapters} za mwanzo.`
      );
     // setLink(`/premium/${num}`);
    }
  }, [book]);

  var myPrice = price;

  const handleMouseEnter = () => {
    setIsHover(true);
  };

  const handleMouseLeave = () => {
    setIsHover(false);
  };

  var bookCover = {
    zIndex: "0",
    backgroundImage: "url(" + placeholder + ")",
    backgroundSize: "cover",
    height: "28vh",
    borderRadius: "15px",
  };

  var backgroundCover = {
    zIndex: "0",
    backgroundImage: "url(" + cover + ")",
    backgroundSize: "cover",
    height: "28vh",
    borderRadius: "15px",
  };

  var blackCover = {
    zIndex: "0",
    backgroundColor: "rgba(0,0,0,0.8)",
    backgroundSize: "cover",
    height: "28vh",
    borderRadius: "15px",
    transition: "2s",
    opacity: isHover ? "0" : "1",
    display: "flex",
    flexDirection: "row"
  };

  var bookImage = {
    position: "absolute",
    top: "5%",
    left: "4%",
    width: "25%",
    zIndex: "0",
    backgroundImage: "url(" + bookPlaceHolder + ")",
    backgroundSize: "cover",
    height: "15vh",
    transition: "2s",
    opacity: isHover ? "0" : "1",
  };

  var statusBody = {
    display: statusDescription === "" ? "none" : "block",
  };

  return (
    <div className="description-body">
      <div className="description-header">
        <header className="home-screen-header">
          <img className="description-screen-logo" src={storiy} alt="storiy" />
        </header>
      </div>

      <div className="description-images" style={bookCover}>
        {spinner ? (
          <div className="description-spinner">
            <Spinner />
          </div>
        ) : (
          <div
            style={backgroundCover}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            className="book-poster"
          >

          <div
            style={blackCover}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <div style={bookImage}>
              <img src={imgSource} alt="" />
            </div>
            
          </div>
          </div>
        )}
      </div>
      <div className="description-content">

        <div className="book-name">
          <h2>{book}</h2>
        </div>

        <div className="book-description">
          <p>
            <span className="light-gray">
              {author !== "" ? `Mwandishi: ` : ""}
            </span>
            <span className="dark-gray">
              {author !== "" ? ` ${author}` : ""}
            </span>
          </p>
          <div className="book-status">
            <h5>
              <Status status={status} />
            </h5>
          </div>
           
          <div className="premium-paying-link">
            <h3 style={statusBody}>{myPrice}</h3>
       
          </div>
          <br/> 
          <p id="content-description">{description}</p>
          
        </div>
        <div className="read-btn">
          <Link to={`/book/${num}/${index}`}>
            <button>
              <strong>Soma hapa</strong>
            </button>
          </Link>
        </div>
        <hr />
          <p className="status-desc" style={statusBody}>
            {statusDescription}
          </p>
      </div>
    </div>
  );
}

export default Descriptionscreen;
