import React, { useRef, useState } from "react";
import { auth } from "../firebase";
import { db } from "../firebase";
import { doc, serverTimestamp, setDoc } from "firebase/firestore";
import LogInscreen from "./LogInscreen";
import storiy from "../images/storiy-logo.svg";
import muro98 from "../images/muro98.svg";

//import androidTwo from "../images/getAndroid2.png";
import "../style/Loginscreen.css";

function Signupscreen() {
  const firstNameRef = useRef(null);
  const lastNameRef = useRef(null);
  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const [errorSignUp, setErrorSignUp] = useState("");
  const [logIn, setLogIn] = useState(false);

  const docData = {
    firstName: "",
    lastName: "",
    email: "",
    userVerified: false,
    startVerificationDate: serverTimestamp(),
    endVerificationDate: serverTimestamp(),
    premiumBookCollection: ["Dragon Slayer", "Web"],
  };

  const createReader = (documentRef, docData) => {
    setDoc(documentRef, docData)
      .then((document) => {
        //console.log("reader Id: " + document.id);
      })
      .catch((error) => {
        // console.log(error.message);
      });
  };

  const register = (e) => {
    e.preventDefault();

    docData.firstName = firstNameRef.current.value;
    docData.lastName = lastNameRef.current.value;
    docData.email = emailRef.current.value;

    if (
      docData.firstName === "" ||
      docData.lastName === "" ||
      docData.email === ""
    ) {
      setErrorSignUp("Jaza sehemu zote ili kukamilisha usajili");

      setTimeout(function() {
        setErrorSignUp(" ");
      }, 5000);
    } else {
      auth
        .createUserWithEmailAndPassword(
          emailRef.current.value,
          passwordRef.current.value
        )
        .then((authUser) => {
          const documentRef = doc(db, "readers", window.$userId);
          createReader(documentRef, docData);
        })
        .catch((error) => {
          setErrorSignUp("Usajili wako haujakamilika, Jaribu tena!");

          setTimeout(function() {
            setErrorSignUp(" ");
          }, 5000);
        });
    }
  };
  return (
    <div className="sign-up-screen">
      {logIn ? (
        <LogInscreen />
      ) : (
        <form className="login-form">
          <img className="login-screen-logo" src={storiy} alt="storiy_logo" />
          <p className="error-message">{errorSignUp}</p>
          <input
            type="text"
            ref={firstNameRef}
            placeholder="First Name.."
            required
          />
          <input
            type="text"
            ref={lastNameRef}
            placeholder="Last Name.."
            required
          />
          <input
            type="email"
            ref={emailRef}
            placeholder="Email address.."
            required
          />
          <input
            type="password"
            ref={passwordRef}
            placeholder="password"
            required
          />
          <button type="submit" onClick={register}>
            <b>Jisajili</b>
          </button>

          <p className="jisajili-size">
          Je, ulishajisajili na Storiy hapo awali? Kulog In{" "}
                <span
                  className="signup-link"
                  onClick={() => {
                    setLogIn(true);
                  }}
                >
                  Bonyeza hapa
                </span>
              </p>
              <img className="login-screen-muro" src={muro98} alt="muro98 logo" />
        </form>
      )}
    </div>
  );
}

export default Signupscreen;
