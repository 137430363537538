import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import dotenv from "dotenv";

dotenv.config();

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "storiy-app.firebaseapp.com",
  projectId: "storiy-app",
  storageBucket: "storiy-app.appspot.com",
  messagingSenderId: "660275058484",
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: "G-0KDW88YH7M",
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const db = firebaseApp.firestore();
const auth = firebase.auth();

export { auth, db };
