import React, { useState, useEffect } from "react";
import storiy from "../images/storiy-logo.svg";
import muro98 from "../images/muro98.svg";
//import LogInscreen from "./LogInscreen";
import "../style/Splashscreen.css";
//import App from "../App";
import LogInscreen from "./LogInscreen";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { login, logout, selectUser } from "../userSlice";
import { auth } from "../firebase";

function Splashscreen() {
  const [logIn, setLogIn] = useState(false);

  const user = useSelector(selectUser);
  const dispatch = useDispatch();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((userAuth) => {
      if (userAuth) {
        //logged in
        window.$userId = userAuth.uid;
        dispatch(
          login({
            uid: userAuth.uid,
            email: userAuth.email,
          })
        );
        //console.log(userAuth);
      } else {
        //logged out
        dispatch(logout());
      }
    });
    return unsubscribe;
  }, []);

  if (!user) {
    setTimeout(function() {
      setLogIn(true);
    }, 3000);
  }

  return (
    <div className="splash-screen-body">
      {logIn ? (
        <LogInscreen />
      ) : (
        <div className="splash-screen-header">
          <div className="splash-screen-logo-container">
            <img className="splash-screen-logo" src={storiy} alt="storiy_logo" /><br/>
            
          </div>
          <small className="splash-screen-mantra">soma kidijitali</small>
          <img className="splash-screen-muro" src={muro98} alt="muro98 logo" />
        </div>
     
      )}
    </div> 
  );
}

export default Splashscreen;
