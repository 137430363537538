import React from "react";
import { useEffect, useState } from "react";
import axios from "../axios";
import "../style/Carousel.css";

function Carousel() {
  const [marginNumber, setMargin] = useState(0);
  const [isHover, setIsHover] = useState(false);
  const [banner, setBanner] = useState([]);
  useEffect(() => {
    async function fetchPosts() {
      const response = await axios.get("/carousel.php");
      setBanner(response.data);
      console.log(response.data);
      return response;

     
    }

    fetchPosts();
  }, [marginNumber]);

  const handleMouseEnter = () => {
    setIsHover(true);
  };

  const handleMouseLeave = () => {
    setIsHover(false);
  };

  var carouselImage = {
    width: "90vw",
    maxWidth: "800px",
  };

  var carouselStyle = {
    width: "100%",
    padding: "0",
    transition: "2s",
    transform: "translateX(" + marginNumber.toString() + "%)",
    animationName: "sliding",
    animationDuration: isHover ? "0s" : "30s",
    animationDelay: "2s",
    animationIterationCount: "infinite",
  };

  return (
    <div className="container">
      <div className="slider">
        <div className="inputs">
          <input type="radio" name="slider-btn" id="radio1" />
          <input type="radio" name="slider-btn" id="radio2" />
          <input type="radio" name="slider-btn" id="radio3" />
          <input type="radio" name="slider-btn" id="radio4" />
        </div>

        {banner.map((post) => {
          return (
            <div className="slide">
              <div style={carouselStyle} key={post.value}>
                <a href={post.source}>
                  <img
                    style={carouselImage}
                    src={post.imageSource}
                    alt={post.title}
                  />
                </a>
              </div>
            </div>
          );
        })}
        <div
          className="nav-manual"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <label
            onClick={() => {
              setMargin(0);
            }}
            for="radio1"
            className="m-btn"
            id="label1"
          ></label>

          <label
            onClick={() => {
              setMargin(-100);
            }}
            for="radio2"
            className="m-btn"
            id="label2"
          ></label>

          <label
            onClick={() => {
              setMargin(-200);
            }}
            for="radio3"
            className="m-btn"
            id="label3"
          ></label>

          <label
            onClick={() => {
              setMargin(-300);
            }}
            for="radio4"
            className="m-btn"
            id="label4"
          ></label>
        </div>
      </div>
    </div>
  );
}

export default Carousel;
