import React from "react";
import { useEffect, useState } from "react";
import axios from "../axios";
import { useParams } from "react-router-dom";
import { db } from "../firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import chapterIcon from "../images/chapters-icon.svg";
import lockIcon from "../images/lock.svg";
import "../style/Chapterscreen.css";
import "../style/Dropdown-menu.css";
import Spinner from "../features/Spinner";

function Chapterscreen() {
  const [open, setOpen] = useState(false);
  const [book, setBook] = useState("");
  const [status, setStatus] = useState(0);
  const [freeChapters, setFreeChapters] = useState(0);
  const [premiumBook, setPremiumBook] = useState([]);

  const [title, setTitle] = useState("");
  const [text, setText] = useState([]);
  const [link, setLink] = useState([]);
  const [chapter, setChapter] = useState(0);
  const [userVerified, setUserVerified] = useState(false);
  const [spinner, setSpinner] = useState(true);
  const { num } = useParams();
  let i = 0;

  useEffect(() => {
    async function fetchBooks() {
      //const response = await axios.get(`/chapter/${num}`);
      const response = await axios.get(`/books.php`);
      const responseTitle = await axios.get("/books.php");

      const data = await response.data;
      const dataTitle = await responseTitle.data;

      let chapterTitle = data[num - 1].chapter[chapter].title;
      let chapterText = data[num - 1].chapter[chapter].text;

      let bookTitle = dataTitle[num - 1].title;
      let statusTitle = dataTitle[num - 1].status;
      let freeChaptersTitle = dataTitle[num - 1].freeChapters;

      setLink(data[num - 1].chapter);
      setTitle(chapterTitle);
      setText(chapterText);

      setBook(bookTitle);
      setStatus(statusTitle);
      setFreeChapters(freeChaptersTitle);

      var dottedString = premiumBook.join(":");
      if (status === 3 && dottedString.indexOf(book) !== -1) {
        setFreeChapters(100);
      }

      if (status === 2 && userVerified) {
        setFreeChapters(100);
      }
      getData();

      return response;
    }

    const getData = async () => {
      try {
        const dateNow = new Date();
        const dateNowInMillis = dateNow.valueOf();

        const docSnap = await getDoc(doc(db, "readers", window.$userId));
        setPremiumBook(docSnap.data().premiumBookCollection);
        const milliSecondsAfterOneMonth = docSnap
          .data()
          .endVerificationDate.toMillis();

        if (dateNowInMillis > milliSecondsAfterOneMonth) {
          const changeUser = {
            userVerified: false,
          };

          await updateDoc(doc(db, "readers", window.$userId), changeUser);
        }

        setUserVerified(docSnap.data().userVerified);
      } catch (error) {
        //error occured
      }
    };

    fetchBooks();

    if (text.length > 1) {
      setSpinner(false);
    } else {
      setSpinner(true);
    }
  }, [chapter, text]);

  let icon = chapterIcon;

  const iconChapter = {
    transitionDuration: "500ms",
    transform: open ? "scale(1)" : "scale(1.5)",
  };

  return (
    <div
      className="chapter-container"
      onClick={() => {
        if (open) {
          setOpen(false);
        }
      }}
    >
      <div className="top-header">
        <div className="book-title">
          <p>{book}</p>
        </div>
        <div className="chapter-header">
          <div className="chapter-title">
            <h1>{title}</h1>
          </div>
          <div
            style={iconChapter}
            onClick={() => {
              setOpen(!open);
            }}
          >
            <img className="icon-blue" src={icon} alt="blue icon" />
          </div>
          <div className={`dropdown-menu-${open ? "active" : "inactive"}`}>
            <ul>
              {link.map((chaps) => {
                return (
                  <li
                    key={chaps.number}
                    onClick={() => {
                      setChapter(
                        chaps.number > freeChapters ? 0 : chaps.number - 1
                      );
                      window.scrollTo(0, 0);
                      setOpen(false);
                    }}
                  >
                    {chaps.number > freeChapters ? (
                      <LockedChapters title={chaps.title} />
                    ) : (
                      <UnlockedChapters title={chaps.title} />
                    )}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
      <div>
        {spinner ? (
          <div className="chapter-spinner">
            <Spinner />
          </div>
        ) : (
          <div className="chapter-text">
            {text.map((doc) => {
              return (
                <p className="doc-style" key={i++}>
                  {doc}
                </p>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}

function LockedChapters(props) {
  const [isHover, setIsHover] = useState(false);

  const handleMouseEnter = () => {
    setIsHover(true);
  };
  const handleMouseLeave = () => {
    setIsHover(false);
  };

  const linkStyle = {
    color: isHover ? "black" : "gray",
  };

  const linkContainer = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  };

  return (
    <div style={linkContainer}>
      <p
        style={linkStyle}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {props.title}
      </p>
      <img src={lockIcon} id="link-image" alt="lock" />
    </div>
  );
}

function UnlockedChapters(props) {
  return <p>{props.title}</p>;
}

export default Chapterscreen;
