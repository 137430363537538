import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Homescreen from "./screens/Homescreen";
import Descriptionscreen from "./screens/Descriptionscreen";
import Chapterscreen from "./screens/Chapterscreen";
// import Splashscreen from "./screens/Splashscreen";
//import LogInscreen from "./screens/LogInscreen";
import { auth } from "./firebase";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { login, logout, selectUser } from "./userSlice";
import "./style/App.css";
import Splashscreen from "./screens/Splashscreen";

function App() {
  const user = useSelector(selectUser);
  const dispatch = useDispatch();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((userAuth) => {
      if (userAuth) {
        //logged in
        window.$userId = userAuth.uid;
        dispatch(
          login({
            uid: userAuth.uid,
            email: userAuth.email,
          })
        );
        //console.log(userAuth);
      } else {
        //logged out
        dispatch(logout());
      }
    });
    return unsubscribe;
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
        {!user ? (
          <Splashscreen />
        ) : (
          <Routes>
            <Route path="/" element={<Homescreen />} />
            <Route path="/book/:num" element={<Descriptionscreen />} />
            <Route path="/book/:num/:name" element={<Chapterscreen />} />
          </Routes>
        )}
      </BrowserRouter>
    </div>
  );
}

export default App;
