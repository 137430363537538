import React, { useState } from "react";
import { useSelector } from "react-redux";
import { selectUser } from "../userSlice";
import { auth } from "../firebase";
import { db } from "../firebase";
import badge from "../images/badge.png";
import { doc, getDoc } from "firebase/firestore";
import profileIcon from "../images/profile-icon.svg";
import "../style/Profilescreen.css";

function Profilescreen() {
  const user = useSelector(selectUser);
  const [nameOfReader, setNameOfReader] = useState(" ");
  const [firstName, setFirstName] = useState(" ");
  const [userVerified, setUserVerified] = useState(false);
  const [startVerificationDate, setStartVerificationDate] = useState(" ");
  const [endVerificationDate, setEndVerificationDate] = useState(" ");
  const [paragraphOne, setParagraphOne] = useState(" ");
  const [paragraphTwo, setParagraphTwo] = useState(" ");
  const [paragraphThree, setParagraphThree] = useState(" ");
  const [price, setPrice] = useState(" ");

  let dateFormat = new Intl.DateTimeFormat("en-GB");

  const getData = async () => {
    try {
      const docSnap = await getDoc(doc(db, "readers", window.$userId));
      const docSnapInfo = await getDoc(doc(db, "info", "web"));

      setPrice(docSnapInfo.data().price);
      setParagraphOne(docSnapInfo.data().paragraphOne);
      setParagraphTwo(docSnapInfo.data().paragraphTwo);
      setParagraphThree(docSnapInfo.data().paragraphThree);
      setNameOfReader(docSnap.data().firstName + " " + docSnap.data().lastName);
      setFirstName(docSnap.data().firstName);
      setUserVerified(docSnap.data().userVerified);
      setStartVerificationDate(docSnap.data().startVerificationDate.toDate());
      setEndVerificationDate(docSnap.data().endVerificationDate.toDate());
    } catch (error) {
      //error occured
    }
  };

  // const updateData = async () => {
  //   try {
  //     const date = serverTimestamp();
  //     const dataToBe = {
  //       userVerified: true,
  //       startVerificationDate: date,
  //     };

  //     await updateDoc(doc(db, "readers", window.$userId), dataToBe);

  //     const docSnapX = await getDoc(doc(db, "readers", window.$userId));
  //     const milliSeconds = docSnapX.data().startVerificationDate.toMillis();
  //     const oneMonth = milliSeconds + 2700000000;
  //     const dateX = Timestamp.fromDate(new Date(oneMonth));

  //     const dataToBeX = {
  //       endVerificationDate: dateX,
  //     };

  //     await updateDoc(doc(db, "readers", window.$userId), dataToBeX);
  //   } catch (error) {
  //     //error occured
  //   }
  // };

  getData();

  return (
    <div className="profile-body">
      <div className="profile-icon">
        <img src={profileIcon} alt="" />
      </div>

      <div className="profile-header">
        <strong>
          {nameOfReader}
          <sup className="badge-icon">
            {userVerified ? <img src={badge} alt="" /> : " "}
          </sup>
        </strong>
        <small>{user.email}</small>

        <p className="profile-info">
          <span className="first-name">{firstName}</span> , {paragraphOne}
        </p>
      </div>
      {userVerified ? (
        <div className="after-payment">
          <p>Umejiunga kifurushi cha mwezi</p>
          <p>Kuanzia: {dateFormat.format(startVerificationDate)}</p>
          <p>Hadi: {dateFormat.format(endVerificationDate)}</p>
          <p className="profile-footer">{paragraphThree}</p>
        </div>
      ) : (
        <div className="payment-container">
          <h1>
            {price + "/"}
            <small>mwezi</small>
          </h1>

          <p id="vitabu-vya-subscription">kwa vitabu vya subscription</p>

          <div className="lipia-button-red">
            <h3>MPESA 0767 456 850</h3>
            <small className="payment-info"> *jina YUSUPH DAUDI MURO</small>
          </div>
          <div className="lipia-button-orange">
            <h3>HALOPESA 0621 842 779</h3>
            <small className="payment-info"> *jina YUSUPH DAUDI MURO</small>
          </div>
          <div className="lipia-button-white">
            <h3>AIRTEL MONEY 0783 377 152</h3>
            <small className="payment-info-black">
              {" "}
              *jina YUSUPH DAUDI MURO
            </small>
          </div>

          <p className="profile-info">
            Kuweza kusoma vitabu vilivyo kwenye kifurushi cha mwezi (vitabu
            vyenye alama nyekundu ya subscription) Lipia kiasi cha Tshs 2,500/-
            kupitia namba zilizotajwa hapo juu kisha tuma neno Storiy kwenda
            Whatsapp namba 0767 456 850 au ujumbe wa kawaida(SMS).
            <br />
            <br />
            Aidha kwa vitabu vya premium (vitabu vyenye alama ya premium)
            utalipia kiasi husika kwenda namba zilizotajwa hapo juu kisha
            utatuma neno Storiy pamoja na kitabu ulichokinunua kwenda Whatsapp
            namba 0767 456 850 au ujumbe wa kawaida(SMS). Kwa vitabu vyenye
            alama ya free hutahitaji kuliipia.
            <br />
            <br />
            <i>soma kidijitali</i>
          </p>

          <br />
          <p className="profile-footer">{paragraphThree}</p>
        </div>
      )}
      <button
        className="sign-out-button"
        onClick={() => {
          auth.signOut();
        }}
      >
        Sign out
      </button>
    </div>
  );
}

export default Profilescreen;
