import React from "react";

function Status(props) {
  if (props.status === 1) {
    return <h6 className="free">Free</h6>;
  } else if (props.status === 2) {
    return <h6 className="subscription">Subscription</h6>;
  } else {
    return <h6 className="premium">Premium</h6>;
  }
}

export default Status;
