import axios from "axios";
import dotenv from "dotenv";

dotenv.config();

const baseUrl = process.env.REACT_APP_BACKEND_URL;

const instance = axios.create({
  baseURL: baseUrl,
});

export default instance;
