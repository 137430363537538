import React from "react";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "../axios";
import Spinner from "./Spinner.js";
import Status from "./Status";
//import badge from "../images/badge.png";
import "../style/App.css";


function Books() {
  const [book, setBook] = useState([]);
  const [spinner, setSpinner] = useState(false);

  useEffect(() => {
    async function fetchBooks() {
      const response = await axios.get("/books.php");
      setBook(response.data);
      return response;
    }

    fetchBooks();

    if (book.length > 1) {
      setSpinner(false);
    } else {
      setSpinner(true);
    }
  }, [book]);

  return (
    <div>
      {spinner ? (
        <div className="home-spinner">
          <Spinner />
        </div>
      ) : (
        <div className="book-slider">
          {book.map((post) => {
            let path = "/book/" + post.value;
            return (
              <div className="books" key={post.value}>
                <Link to={path}>
                  <img src={post.source} alt={post.title} />
                  <p id="the-title">{post.title}</p>
                  <p id="the-author">{post.author}</p>
                  <p>
                    <Status status={post.status} />
                  </p>
                </Link>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default Books;
